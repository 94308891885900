<template>
	<div>
		<div class="form-group">
			<label for="horse_nom">{{ $t('monte.etalon') }} *</label>
			<e-select
				:disabled="disabled"
				v-model="stallion"
				track-by="horse_id"
				label="horse_nom"
				:placeholder="$t('monte.rechercher_stallion')"
				:selectedLabel="$t('global.selected_label')"
				:options="stallions"
				:allow-empty="false"
				:show-labels="false"
				:loading="loadingStallion"
				:sortable="false"
				:class="{ 'is-invalid': errors && errors.indexOf('stallion') > -1 }"
			></e-select>
		</div>
	</div>
</template>

<script type="text/javascript">
	
	import { EventBus } from 'GroomyRoot/assets/js/event-bus.js'
	import Navigation from "@/mixins/Navigation.js"
	import Tools from "@/mixins/Tools.js"
	import ContractMixin from "@/mixins/Contract.js"
	import _sortBy from 'lodash/sortBy'

	export default {
		name: 'Etalon',
		mixins: [Navigation, Tools, ContractMixin],
		props: {
			stallion_id:{
				type: Number,
				default: () => ( 0 )
			},
			value:{
				type: Object,
				default: () => ( null )
			},
			season:{
				type: Object,
				default: () => ( {} )
			},
			errors: {
				type: Array,
				default: () => ( [] )
			},
			disabled:{
				type: Boolean,
				default: () => ( false )
			},
		},
		data () {
			return {
                stallions: [],
                loadingStallion: false,
			}
		},

		computed: {
			stallion: {
				get() {
					return this.value
				},
				set(val) {
					this.$emit('input', val)
				}
			},
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.reloadStallion()
			},

			async reloadStallion(){
				this.loadingStallion = true
				
				let stallion_ids = []
				let contracts_config = []
				let contract_config = null
				
				this.stallion = null

				this.stallions = []

				//Si j'ai une saison je vais chercher les chevaux en fonction de ça, sinon je vais chercher en fonction du contract_config
				if(Object.keys(this.season).length > 0)
				{
					this.stallions = await this.getSeasonStallions(this.season.season_id)
					this.stallions = _sortBy(this.stallions, ['horse_nom'])

					this.stallion_ids = this.getArrayObjProperty(this.stallions, 'horse_id')

					if(this.stallion_id != 0)
					{
						this.stallion = this.stallions.find(stallion => stallion.horse_id == this.stallion_id)
					}
				}

				this.loadingStallion = false
			},

			async defineContractConfig(){

				if(this.stallion != null && Object.keys(this.season).length > 0)
				{
					let contract_config = await this.getContractsConfigByStallionAndSeason(this.stallion.horse_id, this.season.season_id)
					this.$emit("update:contractconfig_id", contract_config[0].contractconfig_id)
				}
				else
				{
					this.$emit("update:contractconfig_id", 0)
				}
			}
		},
		watch: {
			season(val){
				if(val != null){
					this.reloadStallion()
				}
			},
			stallion(val){
				//Je vais définir le nouveau contract_config puisque l'étalon a changé
				this.defineContractConfig()
			},
		},

		components: {

		}
	}

</script>